import * as React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Slide, SnackbarContent, Snackbar as MUISnackbar } from '@material-ui/core';

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

interface OwnProps {
  open: boolean;
  autoHideDuration?: number;
  message: string | React.ReactNode;
  type?: 'default' | 'success' | 'information' | 'danger';
  onClose: () => void;
}

export const Snackbar: React.FunctionComponent<OwnProps> = ({
  open,
  autoHideDuration = 5000,
  message,
  type = 'default',
  onClose,
}) => (
  <MUISnackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    TransitionComponent={SlideTransition}
  >
    <SnackbarContent
      className={`content-${type}`}
      message={typeof message === 'string' ? <span>{message}</span> : message}
    />
  </MUISnackbar>
);
